@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&family=Montserrat:wght@100;200;300;400;500;700;900&family=Raleway:wght@200;400&display=swap");

#gallery {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

#gallery-header {
  padding: 3rem;
  margin-top: 3rem;
}

#gallery h3 {
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 200;
  letter-spacing: 8px;
}

#gallery-container {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 2rem;
}

.gallery-item {
  width: 290px;
  height: 500px;
  margin: 1rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.photo {
  width: 100%;
  height: 100%;
}

.gallery-description {
  position: absolute;
  margin: 13px;
  text-align: center;
  color: white;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s;
  z-index: 20;
}

.gallery-item:hover .gallery-description {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.5s;
}

.gallery-item:hover .photo {
    filter: blur(0.5rem) brightness(0.6);
}