@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&family=Montserrat:wght@100;200;300;400;500;700;900&family=Raleway:wght@200;400&display=swap");

#contact {
  height: 100%;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

#contact-image {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 0;
}

#contact-heading {
  padding: 3rem;
  margin-top: 3rem;
  z-index: 1;
}

#contact-body {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 2rem;
  justify-content: space-evenly;
  align-items: center;
  z-index: 1;
}

#contact-information {
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
}

#contact-information div {
  width: 100%;
  text-align: center;
  margin: 0.5rem;
}

.day,
.time {
  width: 50%;
}

.icon i {
  font-size: 4rem;
  color: #574d4f;
}

.icon i:hover {
  color: #fff;
}

.icon:first-of-type {
  text-align: right;
}

#form {
  width: 70%;
}

#message {
  overflow: scroll;
  min-height: 200px;
}

.btn:disabled {
  background-color: #574d4f9f;
}

@media (max-width: 1000px) {
  #contact-information,
  #form {
    width: 90%;
  }
  #contact-information div {
    width: 100%;
    text-align: center;
  }
  #contact-image {
    visibility: hidden;
  }
  #contact {
    align-items: center;
  }
  #contact-body {
    width: 100%;
  }
}
