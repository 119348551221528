@import url("https://fonts.googleapis.com/css2?family=Allura:wght@400;500;600;700&family=Montserrat:wght@100;200;300;400;500;700;900&family=Raleway:wght@200;400&display=swap");

/*Home*/
#home {
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

#home-content {
  text-align: center;
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

#title {
  font-family: "Allura", sans-serif;
  z-index: 1;
  font-size: 11rem;
  letter-spacing: 6px;
  margin: 0;
}

.subheading {
  text-transform: uppercase;
  letter-spacing: 3px;
  margin-bottom: 3rem;
}

#home-image {
  width: 100vw;
  height: 100vh;
  z-index: 0;
} 
/*End of Home*/

/*About*/
#about {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100vw;
  height: 100%;
  padding: 5rem;
}

#about-paragraph,
#about-info {
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

#about-paragraph {
  width: 40%;
}

#about-info {
  width: 40%;
}

#about-paragraph h3,
#about-paragraph p,
#contact-info {
  font-weight: 300;
}

#about-paragraph h3 {
  margin-top: 3rem;
  font-weight: 700;
}

#about-paragraph p {
  font-size: 1.2rem;
}

#contact-info {
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

#contact-info div {
  padding: 0.4rem;
  text-align: center;
}

.day {
  text-align: right;
}

#social-info {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}

#social-info i {
  font-size: 4rem;
  color: black;
  margin: 1rem;
}

#social-info i:hover {
  color: #ebcdd5;
}
/*End of About*/

/*Review*/
#review {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#review-image {
  width: 100%;
  height: 800px;
  background-image: url("https://images.unsplash.com/photo-1544816135-b44f18b3c5d6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80");
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

#review-posts {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  width: 50%;
  justify-content: space-evenly;
  align-items: flex-start;
}

.review {
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  font-size: 1.2rem;
}

.stars,
.review-text {
  margin-bottom: 0.5rem;
}

.signature {
  font-size: 1rem;
}

#review-footer {
  background-color: #ebcdd5;
  width: 100%;
  height: 40px;
}
/*End of Review*/

/*Responsive*/
@media (max-width: 1200px) {
  #title {
    font-size: 9rem;
  }
  #about {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  #about-paragraph,
  #about-info {
    width: 90%;
  }
  #about-paragraph h3,
  #about-paragraph p {
    width: 90%;
  }

  #about-paragraph {
    text-align: center;
  }
  #home-image {
    height: 90vh;
  }
}
@media (max-width: 1000px) {
  #title {
    font-size: 9rem;
  }
  #review-posts {
    width: 75%;
  }
    #review-image {
    height: 600px;
  }
}

@media (max-width: 800px) {
  #title {
    font-size: 7rem;
  }
  .review {
    width: 100%;
  }
  #home {
    justify-content: center;
    align-items: center;
  }
  #home-content {
    margin: 0;
  }
  #home-image {
    height: 75vh;
  }
  #review-image {
    height: 400px;
  }
}

@media (max-width: 600px) {
  .subheading {
    font-size: 1.5rem;
  }
  #home-image {
    height: 60vh;
  }
  #review-image {
    height: 310px;
  }
  #title,
  #about-heading,
  #review-heading {
    font-size: 4.8rem;
  }
}
@media (max-width: 500px) {
  #about {
    padding: 2rem;
  }
  #home-image {
    height: 50vh;
  }
  #review-posts {
    width: 90%;
  }
}
/*End of Responsive*/
