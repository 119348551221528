@import url("https://fonts.googleapis.com/css2?family=Allura:wght@400;500;600;700&family=Montserrat:wght@100;200;300;400;500;700;900&family=Raleway:wght@200;400&display=swap");

/*Common*/
body {
  font-family: "Montserrat", sans-serif;
}

.section {
  width: 100vw;
}

.heading {
  font-family: "Allura", sans-serif;
  font-size: 7rem;
  letter-spacing: 4px;
  color: #fff;
  text-shadow: 0 0 7px #ebcdd5, 0 0 10px #ebcdd5, 0 0 21px #ebcdd5,
    0 0 42px #ebcdd5, 0 0 82px #ebcdd5;
}

.btn:active {
  transform: scale(0.9);
  transition: transform ease;
}

.btn.btn-primary {
  background-color: #883d3d54;
  border: 1px solid black;
  outline: none;
  margin: 1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1.2rem;
  width: 200px;
  box-shadow: none;
  color: black;
}

.btn.btn-primary:hover,
.btn.btn-primary:focus,
.btn.btn-primary::before,
.btn.btn-primary:active {
  border: 1px solid white;
  background-color: #883d3d54;
  outline: none;
  color: white;
}

.btn.btn-primary::after {
  box-shadow: none;
}
/*End of Common*/

/*Navbar*/
#navbar {
  width: 100vw;
  background-color: #ebcdd5;
  z-index: 24;
}

.me-auto {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  text-transform: uppercase;
  font-size: 1.4rem;
  z-index: 21;
}

.me-auto a:hover {
  font-weight: 500;
  transition: transform ease;
}

@media (max-width: 600px) {
  .heading {
    font-size: 3.7rem;
  }
}
/*End of Navbar*/
